body {
  background-color: white;
  font-size: 16px;
  padding: 0.5rem 1rem;
  line-height: 1.33;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

a {
  display: block;
  color: blue;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &:visited {
    text-decoration: line-through;
    color: blueviolet;
  }
}
